<template>
  <v-main class="container-prosker mt-5">
    <delete-warning
      :confirm-delete="confirmDelete"
      :on-close="onDeleteClose"
      :show-delete="showDelete"
      :loading="loading"
    />
    <v-row>
      <v-main class="text-center">
        <h1 class="black--text">{{ $t("myAddresses") }}</h1>
      </v-main>
    </v-row>
    <v-row class="ml-1 mb-2">
      <v-col class="text-center">
        <user-addresses-dialog
          :item="editedItem"
          :on-close="onAddClose"
          :new-item="newItem"
          :show-add="showAdd"
        />
      </v-col>
    </v-row>
    <v-row v-if="loadingList">
        <v-col v-for="index in 6" :key="index" cols="12" md="2">
            <v-skeleton-loader
                    v-bind="skeletonAttrs"
                    type="list-item-avatar, divider, list-item-three-line, card-heading, actions"
            ></v-skeleton-loader>
        </v-col>
    </v-row>
    <v-row v-if="!loadingList && userAddresses.length !== 0">
      <template>
        <v-col
          cols="12"
          md="4"
          lg="3"
          v-for="(address, i) in this.userAddresses"
          :key="i"
        >
          <v-card class="rounded-xl">
            <v-card-title>
              <strong>{{ $t("domicile") }}: {{ address.line_1 }}</strong>
            </v-card-title>
            <v-card-text class="py-0">
              <span>{{ $t("postalCode") }}: {{ address.postal_code }}</span>
              <br />
              <span>{{ $t("country") }}: {{ address.country }}</span>
              <br />
              <span>{{ $t("city") }}: {{ address.city }}</span>
              <br />
              <span>{{ $t("state") }}: {{ address.state }}</span>
            </v-card-text>
            <v-divider></v-divider>
            <v-card-actions class="pt-0">
              <v-chip class="green-prosk bg-prosk-secondary font-weight-bold" v-if="address.is_default">
                {{ $t("isDefault") }}
              </v-chip>
              <v-spacer></v-spacer>
              <v-btn icon color="red" @click="openWarning(address.id)">
                <v-icon>mdi-delete</v-icon>
              </v-btn>
              <v-btn icon color="secondary" @click="editItem(address)">
                <v-icon>mdi-pencil</v-icon>
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-col>
      </template>
    </v-row>
    <v-row v-if="!loadingList && userAddresses.length === 0">
      <v-col class="text-center mt-15">
        <h2>{{ $t("noAddresses") }}</h2>
      </v-col>
    </v-row>
  </v-main>
</template>
<script>
import axios from 'axios';
import DeleteWarning from '@/components/socialvue/dialogs/DeleteWarning';
import { mapGetters } from 'vuex';
import UserAddressesDialog from '@/components/socialvue/dialogs/UserAddressesDialog';
import errorDialog from '@/components/socialvue/mixins/errorDialog';

export default {
  name: 'UserAddresses',
  components: { UserAddressesDialog, DeleteWarning },
  mixins: [errorDialog],
  data () {
    return {
      showAdd: false,
      userAddresses: [],
      editedItem: {},
      loading: false,
      id: 0,
      itemToDelete: '',
      showDelete: false,
      loadingList: false,
      skeletonAttrs: {
        class: 'mr-4',
        boilerplate: true,
        elevation: 2
      }
    };
  },
  mounted () {
    window.scroll({
      top: 0,
      behavior: 'smooth'
    });
    this.getUserAddresses();
  },
  computed: {
    ...mapGetters({
      currentUser: 'auth/currentUser'
    })
  },
  methods: {
    getUserAddresses () {
      this.loadingList = true;
      const url = `${process.env.VUE_APP_BASE_URL}/api/users/`;
      axios.get(url + this.currentUser.id).then(({ data }) => {
        this.userAddresses = data.data.addresses.sort(function (a, b) {
          if (a.is_default > b.is_default) {
            return 1;
          }
          if (a.is_default < b.is_default) {
            return -1;
          }
          return 0;
        });
        this.loadingList = false;
      })
      .catch((error) => {
        this.loadingList = false;
        this.showError(error);
      });
    },
    openWarning (item) {
      this.showDelete = true;
      this.itemToDelete = item;
    },
    onDeleteClose () {
      this.showDelete = false;
    },
    confirmDelete () {
      this.loading = true;
      const url =
        `${process.env.VUE_APP_BASE_URL}/api/addresses/` + this.itemToDelete;
      axios
        .delete(url)
        .then(() => {
          this.getUserAddresses();
          this.loading = false;
        })
        .catch((error) => {
          this.loading = false;
          this.showError(error);
        });
      this.showDelete = false;
    },
    newItem () {
      this.showAdd = true;
      this.editedItem.isEdit = false;
    },
    onAddClose () {
      this.getUserAddresses();
      this.editedItem = {};
      this.showAdd = false;
      this.editedItem.isEdit = false;
    },
    editItem (item) {
      this.editedItem = item;
      this.editedItem.isEdit = true;
      this.showAdd = true;
    }
  }
};
</script>
